.nice-select {
  -webkit-tap-highlight-color: #0000;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  float: left;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  outline: none;
  width: auto;
  height: 38px;
  padding-left: 18px;
  padding-right: 30px;
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  line-height: 36px;
  transition: all .2s ease-in-out;
  display: block;
  position: relative;
  text-align: left !important;
}

.nice-select:hover {
  border-color: #dbdbdb;
}

.nice-select:active, .nice-select.open, .nice-select:focus {
  border-color: #999;
}

.nice-select:after {
  content: "";
  pointer-events: none;
  transform-origin: 66% 66%;
  border-bottom: 2px solid #999;
  border-right: 2px solid #999;
  width: 5px;
  height: 5px;
  margin-top: -4px;
  transition: all .15s ease-in-out;
  display: block;
  position: absolute;
  top: 50%;
  right: 12px;
  transform: rotate(45deg);
}

.nice-select.open:after {
  transform: rotate(-135deg);
}

.nice-select.open .nice-select-dropdown {
  opacity: 1;
  pointer-events: auto;
  transform: scale(1)translateY(0);
}

.nice-select.disabled {
  color: #999;
  pointer-events: none;
  border-color: #ededed;
}

.nice-select.disabled:after {
  border-color: #ccc;
}

.nice-select.wide {
  width: 100%;
}

.nice-select.wide .nice-select-dropdown {
  left: 0 !important;
  right: 0 !important;
}

.nice-select.right {
  float: right;
}

.nice-select.right .nice-select-dropdown {
  left: auto;
  right: 0;
}

.nice-select.small {
  height: 36px;
  font-size: 12px;
  line-height: 34px;
}

.nice-select.small:after {
  width: 4px;
  height: 4px;
}

.nice-select.small .option {
  min-height: 34px;
  line-height: 34px;
}

.nice-select .nice-select-dropdown {
  pointer-events: none;
  transform-origin: 50% 0;
  z-index: 9;
  opacity: 0;
  background-color: #fff;
  border-radius: 5px;
  margin-top: 4px;
  transition: all .2s cubic-bezier(.5, 0, 0, 1.25), opacity .15s ease-out;
  position: absolute;
  top: 100%;
  left: 0;
  transform: scale(.75)translateY(19px);
  box-shadow: 0 0 0 1px #4444441c;
}

.nice-select .list {
  box-sizing: border-box;
  border-radius: 5px;
  max-height: 210px;
  padding: 0;
  overflow: hidden auto;
}

.nice-select .list:hover .option:not(:hover) {
  background-color: #0000 !important;
}

.nice-select .option {
  cursor: pointer;
  text-align: left;
  outline: none;
  padding-left: 18px;
  padding-right: 29px;
  font-weight: 400;
  line-height: 40px;
  list-style: none;
  transition: all .2s;
}

.nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
  background-color: #f6f6f6;
}

.nice-select .option.selected {
  font-weight: bold;
}

.nice-select .option.disabled {
  color: #999;
  cursor: default;
  background-color: #0000;
}

.nice-select .optgroup {
  font-weight: bold;
}

.no-csspointerevents .nice-select .nice-select-dropdown {
  display: none;
}

.no-csspointerevents .nice-select.open .nice-select-dropdown {
  display: block;
}

.nice-select .list::-webkit-scrollbar {
  width: 0;
}

.nice-select .has-multiple {
  white-space: inherit;
  height: auto;
  min-height: 36px;
  padding: 7px 12px;
  line-height: 22px;
}

.nice-select .has-multiple span.current {
  background: #eee;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-bottom: 3px;
  margin-right: 3px;
  padding: 0 10px;
  font-size: 14px;
  line-height: 24px;
  display: inline-block;
}

.nice-select .has-multiple .multiple-options {
  padding: 0;
  line-height: 24px;
  display: block;
}

.nice-select .nice-select-search-box {
  box-sizing: border-box;
  pointer-events: none;
  border-radius: 5px 5px 0 0;
  width: 100%;
  padding: 5px;
}

.nice-select .nice-select-search {
  box-sizing: border-box;
  color: #444;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  width: 100%;
  height: auto;
  min-height: 36px;
  margin: 0 10px 0 0;
  padding: 7px 12px;
  font-size: 14px;
  line-height: 22px;
  display: inline-block;
  outline: 0 !important;
}
/*# sourceMappingURL=index.c6e2cce2.css.map */
